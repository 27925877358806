// This is quite a HACK to get rollover on desktop and slightly comprises large tablets.
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MenuComponent } from '../menu.component';

@Component({
    selector: 'app-menu-mega',
    templateUrl: './../../../../app/navigation/menu/menu-mega/menu-mega.component.html',
    styleUrls: ['./../../../../app/navigation/menu/menu-mega/menu-mega.component.scss'],
    standalone: false
})

export class MenuMegaComponent implements OnInit {
  public timedOutCloser: NodeJS.Timeout;

  constructor(@Inject(MenuComponent) public menuComponent: MenuComponent) { }
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Input() itemName: string;
  @Input() mega: boolean;
  @Input() rollOver: boolean = true;
  @Input() clickFix: boolean = false;
  @Input() closeOthers: boolean = false;

  megaClass = '';
  firstClick = true;

  ngOnInit(): void {
    if (this.mega) {
      this.megaClass = 'mega-menu';
    }
  }

  initialClick(trigger: MatMenuTrigger): void {
    if (this.rollOver || this.clickFix) {
      return;
    }
    if (this.firstClick) {
      this.firstClick = false;
      trigger.openMenu();
      this.addTopClass(trigger);
      this.openMegaMenu();
      if (this.closeOthers) {
        this.menuComponent.closeOthers(trigger);
      }
    } else {
      this.firstClick = true;
      trigger.closeMenu();
      this.removeTopClass();
    }
  }

  closeMenu(): void {
    this.firstClick = true;
    this.menuTrigger?.closeMenu();
    this.removeTopClass();
  }

  mouseEnter(trigger: MatMenuTrigger, $event: MouseEvent): void {
    if (!this.rollOver) {
      return;
    }
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
    this.addTopClass($event);
    this.openMegaMenu();
  }

  mouseLeave(trigger: MatMenuTrigger, $event: MouseEvent): void {
    if (!this.rollOver) {
      return;
    }
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
      this.removeTopClass();
    }, 200);
  }

  addTopClass(trigger: any) {
    if (trigger?.target) {
      trigger.target.classList?.add('menu-hover-state');
    }
  }

  removeTopClass() {
    const hovers = document.getElementsByClassName('menu-hover-state');
    [].forEach.call(hovers, function (el) {
      el?.classList.remove('menu-hover-state');
    });
  }

  openMegaMenu(): void {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0]?.classList.contains('mega-menu')) {
          el?.classList.add('mega-menu-pane');
        }
      }
    });
  }
}
